<template>
  <router-view></router-view>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
onMounted(() => {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();
});
</script>
<style>
:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}
@media only screen and (min-width: 576px) {
  body {
    width: 576px;
    margin: 0 auto;
  }
}
</style>
