import { createApp } from "vue";

import { createPinia } from "pinia";

import { Notifier } from "@airbrake/browser";
import * as FullStory from "@fullstory/browser";

import App from "./App.vue";
import router from "./router";
import drag from "v-drag";

import "./style.css";

const app = createApp(App);

if (import.meta.env.VITE_AIRBRAKE_ENABLED) {
  const airbrake = new Notifier({
    environment: import.meta.env.VITE_AIRBRAKE_ENVIRONMENT,
    projectId: Number(import.meta.env.VITE_AIRBRAKE_PROJECT_ID),
    projectKey: import.meta.env.VITE_AIRBRAKE_PROJECT_API_KEY,
  });

  app.config.errorHandler = function (err: any, _vm: any, info: any) {
    void airbrake.notify({
      error: err,
      params: { info: info },
    });
  };
}

FullStory.init({ orgId: "o-1AVSE7-na1" });

app.use(router);
app.use(createPinia());
app.use(drag);
app.mount("#app");
