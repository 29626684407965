import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";

function lazyView(view: string) {
  return () => import(`../pages/${view}.vue`);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/3ds-authentication-complete",
    name: "3DSAuthenticationCompletePage",
    component: lazyView("3DSAuthenticationComplete"),
  },
  {
    path: "/:pos/:company?/:site/:table",
    name: "HomePage",
    component: lazyView("Home"),
  },
  {
    path: "/payments/:payment",
    name: "PaymentPage",
    component: lazyView("Payment"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: lazyView("NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
